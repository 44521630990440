<template>
    <div class="custom-content">
        <div class="custom-wrap">
            <el-table class="customTable" :data="customList" style="width: 100%; flex: 1;" height="1%"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="content" label="内容" align="left"></el-table-column>
                <el-table-column prop="content" label="分析" align="center">
                    <template slot-scope="scope">
                        <div class="item-content" v-if="scope.$index == 0">
                            <el-input class="item-input mini" ref="user_analysis_age_begin"
                                      v-model="scope.row.user_analysis_age_begin"></el-input>
                            <span class="input-text">岁</span>
                            <div class="divide"></div>
                            <el-input class="item-input mini" ref="user_analysis_age_end"
                                      v-model="scope.row.user_analysis_age_end"></el-input>
                            <span class="input-text">岁</span>
                        </div>
                        <div class="item-content" v-if="scope.$index == 1">
                            <el-input class="item-input medium" ref="user_analysis_region_distribution"
                                      v-model="scope.row.user_analysis_region_distribution"
                                      placeholder="多个地区用“#”分开"></el-input>
                        </div>
                        <div class="item-content" v-if="scope.$index == 2">
                            <span class="input-text sex">男</span>
                            <el-input class="item-input sex" @input="GenderMale" ref="user_analysis_man_ratio"
                                      v-model="scope.row.user_analysis_man_ratio"
                                      v-one-number></el-input>
                            <span class="input-text">%</span>
                            <span class="input-text sex">女</span>
                            <el-input class="item-input sex" @input="GenderFemale"
                                      ref="user_analysis_woman_ratio"
                                      v-model="scope.row.user_analysis_woman_ratio"
                                      v-one-number></el-input>
                            <span class="input-text">%</span>
                        </div>
                        <div class="item-content" v-if="scope.$index == 3">
                            <el-input class="item-input medium" ref="user_analysis_style_preference"
                                      v-model="scope.row.user_analysis_style_preference"
                                      placeholder="多个款式用“#”分开"></el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page-button">
                <el-button type="primary" @click="onSubmit">提交</el-button>
            </div>
        </div>
        <successDialog :successDialogVisible="successDialogVisible" @closeDialog="handleClose"></successDialog>
    </div>
</template>

<script>
    import oneNumber from "@/directive/index";
    import successDialog from "@/components/successDialog";
    import {stuOpSaveUserAnalysis, stuOpUserAnalysisDetail} from '@/utils/apis'

    export default {
        name: "cutomAnalysis",
        components: {successDialog},
        directives: {
            oneNumber
        },
        data() {
            return {
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                customList: [
                    {
                        content: '年龄范围',
                        analysis: '',
                        user_analysis_age_begin: '',
                        user_analysis_age_end: '',
                    },
                    {
                        content: '地域分布',
                        analysis: '',
                        user_analysis_region_distribution: ''
                    },
                    {
                        content: '性别比例',
                        analysis: '',
                        user_analysis_man_ratio: '',
                        user_analysis_woman_ratio: '',
                    },
                    {
                        content: '款式喜好',
                        analysis: '',
                        user_analysis_style_preference: ''
                    },
                ],
                successDialogVisible: false,
                platform: this.$route.meta.type,
            }

        },
        created() {
            this.getUserAnalysis();
        },
        methods: {
            getUserAnalysis() {
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId
                }
                stuOpUserAnalysisDetail(param).then(res => {
                    if (res.data !== null) {
                        this.customList[0].user_analysis_age_begin = res.data.age_begin;
                        this.customList[0].user_analysis_age_end = res.data.age_end;
                        this.customList[1].user_analysis_region_distribution = res.data.region_distribution;
                        this.customList[2].user_analysis_man_ratio = res.data.man_ratio;
                        this.customList[2].user_analysis_woman_ratio = res.data.woman_ratio;
                        this.customList[3].user_analysis_style_preference = res.data.style_preference;
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            GenderMale() {
                if (this.customList[2].user_analysis_man_ratio > 100) {
                    this.customList[2].user_analysis_man_ratio = 100
                }
                this.customList[2].user_analysis_woman_ratio = (100 - this.customList[2].user_analysis_man_ratio).toFixed(1)
            },
            GenderFemale() {
                if (this.customList[2].user_analysis_woman_ratio > 100) {
                    this.customList[2].user_analysis_woman_ratio = 100
                }
                this.customList[2].user_analysis_man_ratio = (100 - this.customList[2].user_analysis_woman_ratio).toFixed(1)
            },
            onSubmit() {
                let isErrNum = 0;
                if (!this.customList[0].user_analysis_age_begin) {
                    this.$refs.user_analysis_age_begin.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_age_begin.$el.classList.remove('isError');
                }
                if (!this.customList[0].user_analysis_age_end) {
                    this.$refs.user_analysis_age_end.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_age_end.$el.classList.remove('isError');
                }

                if (!this.customList[1].user_analysis_region_distribution) {
                    this.$refs.user_analysis_region_distribution.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_region_distribution.$el.classList.remove('isError');
                }
                if (this.customList[2].user_analysis_man_ratio == '') {
                    this.$refs.user_analysis_man_ratio.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_man_ratio.$el.classList.remove('isError');
                }
                if (this.customList[2].user_analysis_woman_ratio == '') {
                    this.$refs.user_analysis_woman_ratio.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_woman_ratio.$el.classList.remove('isError');
                }
                if (!this.customList[3].user_analysis_style_preference) {
                    this.$refs.user_analysis_style_preference.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.user_analysis_style_preference.$el.classList.remove('isError');
                }

                if (isErrNum === 0) {
                    let param = {
                        age_begin: this.customList[0].user_analysis_age_begin,
                        age_end: this.customList[0].user_analysis_age_end,
                        region_distribution: this.customList[1].user_analysis_region_distribution,
                        man_ratio: this.customList[2].user_analysis_man_ratio,
                        woman_ratio: this.customList[2].user_analysis_woman_ratio,
                        style_preference: this.customList[3].user_analysis_style_preference,
                    }
                    if (this.platform == 0) {
                        param.op_id = this.opId;
                        param.course_id = this.courseId
                    }
                    stuOpSaveUserAnalysis(param).then(res => {
                        this.successDialogVisible = true;
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    this.$message.warning('每项为必填！')
                }
            },
            handleClose() {
                this.successDialogVisible = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-content {
        margin-top: 18px;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        padding-bottom: 52px;

        .custom-wrap {
            margin: 0 20px;
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;

            .item-content {
                display: flex;
                align-items: center;
                justify-content: center;

                .divide {
                    width: 20px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 10px;
                }
            }

            .input-text {
                margin: 0 10px;

                &.sex {
                    margin: 0 17px;
                }

                &.front {
                    margin: 0 12px 0 22px;
                }
            }

            .item-input {
                &.medium {
                    width: 500px;
                }

                &.mini {
                    width: 200px;
                }

                &.sex {
                    width: 184px;
                }

                &.tiny {
                    width: 120px;
                }
            }

            .page-button {
                text-align: center;
            }
        }
    }
</style>